export default {
  updateLastListUrl(state, value) {
    state.lastListUrl = value;
  },
  updateAdminLastListUrl(state, value) {
    state.adminLastListUrl = value;
  },
  updateLastRoute(state, value) {
    state.lastRoute = value;
  },
};
