<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>{{ $t("Terms") }}</h2>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur
        culpa, quos? Aperiam architecto earum harum impedit, inventore magni
        nihil odio perferendis quam tempora! Debitis dignissimos odit
        reprehenderit rerum sunt tempora?
      </div>
      <div class="col-12 col-md-4 mb-3">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium
        aspernatur consequuntur, cumque deleniti ducimus eaque eos excepturi
        explicabo, id maxime natus necessitatibus nesciunt odit quam, sequi
        temporibus voluptate. Maiores, nesciunt?
      </div>
      <div class="col-12 col-md-4 mb-3">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem commodi
        dolor eaque eligendi error est fugiat impedit ipsam maiores, minima nisi
        omnis provident tempora! Hic laboriosam laudantium quae quidem saepe!
      </div>
    </div>
  </div>
</template>

<script>
import { updateAppMeta } from "@/utils/app";

export default {
  name: "TermsView",
  created() {
    updateAppMeta({
      title: this.$t("Terms"),
    });
  },
};
</script>
