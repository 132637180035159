export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "form": {
    "validation": {
      "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Min. ", _interpolate(_list(0)), " signs."])},
      "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Max. ", _interpolate(_list(0)), " signs."])},
      "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Min. value ", _interpolate(_list(0)), "."])},
      "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Max. value ", _interpolate(_list(0)), "."])},
      "equalValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The value is not equal ", _interpolate(_list(0)), "."])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out this field."])},
      "requiredCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check this field."])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email is not valid."])},
      "isNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not a number."])}
    }
  },
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "Agree with the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree with the"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])},
  "Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "More information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "The website uses cookies in order to personalise the website.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website uses cookies in order to personalise the website."])},
  "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "Forgot Your Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Your Password?"])},
  "Reset password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "Reset Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "Send Password Reset Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Password Reset Link"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "Send a message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])},
  "If you have questions or suggestions please contact me via the following form.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have questions or suggestions please contact me via the following form."])},
  "The message has been sent properly.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message has been sent properly."])},
  "An error has occurred. Please try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later."])},
  "Name and Surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and Surname"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "Write the correct sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the correct sum"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "Roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "Change password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "You are logged in!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in!"])},
  "Admin panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin panel"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "Confirm new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "We have emailed your password reset link!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have emailed your password reset link!"])},
  "Your password has been reset!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been reset!"])},
  "Your password has been changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed"])},
  "Last news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last news"])},
  "Add news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add news"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "List of news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of news"])},
  "The sum is not correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sum is not correct"])}
}